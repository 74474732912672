exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-backup-lancamento-js": () => import("./../../../src/pages/backup.lancamento.js" /* webpackChunkName: "component---src-pages-backup-lancamento-js" */),
  "component---src-pages-backup-lancamentos-js": () => import("./../../../src/pages/backup.lancamentos.js" /* webpackChunkName: "component---src-pages-backup-lancamentos-js" */),
  "component---src-pages-boletins-tecnicos-js": () => import("./../../../src/pages/boletins-tecnicos.js" /* webpackChunkName: "component---src-pages-boletins-tecnicos-js" */),
  "component---src-pages-busca-avancada-index-jsx": () => import("./../../../src/pages/busca/avancada/index.jsx" /* webpackChunkName: "component---src-pages-busca-avancada-index-jsx" */),
  "component---src-pages-busca-index-jsx": () => import("./../../../src/pages/busca/index.jsx" /* webpackChunkName: "component---src-pages-busca-index-jsx" */),
  "component---src-pages-busca-produto-index-jsx": () => import("./../../../src/pages/busca/produto/index.jsx" /* webpackChunkName: "component---src-pages-busca-produto-index-jsx" */),
  "component---src-pages-busca-veiculo-index-jsx": () => import("./../../../src/pages/busca/veiculo/index.jsx" /* webpackChunkName: "component---src-pages-busca-veiculo-index-jsx" */),
  "component---src-pages-canal-aberto-wega-js": () => import("./../../../src/pages/canal-aberto-wega.js" /* webpackChunkName: "component---src-pages-canal-aberto-wega-js" */),
  "component---src-pages-category-boletins-tecnicos-js": () => import("./../../../src/pages/category/boletins-tecnicos.js" /* webpackChunkName: "component---src-pages-category-boletins-tecnicos-js" */),
  "component---src-pages-category-noticias-wega-js": () => import("./../../../src/pages/category/noticias-wega.js" /* webpackChunkName: "component---src-pages-category-noticias-wega-js" */),
  "component---src-pages-category-videos-js": () => import("./../../../src/pages/category/videos.js" /* webpackChunkName: "component---src-pages-category-videos-js" */),
  "component---src-pages-category-voce-sabia-js": () => import("./../../../src/pages/category/voce-sabia.js" /* webpackChunkName: "component---src-pages-category-voce-sabia-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-dica-js": () => import("./../../../src/pages/dica.js" /* webpackChunkName: "component---src-pages-dica-js" */),
  "component---src-pages-dicas-js": () => import("./../../../src/pages/dicas.js" /* webpackChunkName: "component---src-pages-dicas-js" */),
  "component---src-pages-duvidas-tecnicas-js": () => import("./../../../src/pages/duvidas-tecnicas.js" /* webpackChunkName: "component---src-pages-duvidas-tecnicas-js" */),
  "component---src-pages-formulario-de-garantia-wega-js": () => import("./../../../src/pages/formulario-de-garantia-wega.js" /* webpackChunkName: "component---src-pages-formulario-de-garantia-wega-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lancamentos-js": () => import("./../../../src/pages/lancamentos.js" /* webpackChunkName: "component---src-pages-lancamentos-js" */),
  "component---src-pages-page-js": () => import("./../../../src/pages/page.js" /* webpackChunkName: "component---src-pages-page-js" */),
  "component---src-pages-produtos-js": () => import("./../../../src/pages/produtos.js" /* webpackChunkName: "component---src-pages-produtos-js" */),
  "component---src-pages-quem-somos-js": () => import("./../../../src/pages/quem-somos.js" /* webpackChunkName: "component---src-pages-quem-somos-js" */),
  "component---src-pages-solicitacao-de-palestra-ou-treinamento-js": () => import("./../../../src/pages/solicitacao-de-palestra-ou-treinamento.js" /* webpackChunkName: "component---src-pages-solicitacao-de-palestra-ou-treinamento-js" */),
  "component---src-pages-solicitacao-de-visita-tecnica-js": () => import("./../../../src/pages/solicitacao-de-visita-tecnica.js" /* webpackChunkName: "component---src-pages-solicitacao-de-visita-tecnica-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-trabalhe-conosco-js": () => import("./../../../src/pages/trabalhe-conosco.js" /* webpackChunkName: "component---src-pages-trabalhe-conosco-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */)
}

